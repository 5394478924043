import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ProfileGuard } from '../profile/guards/profile.guard';
import { LayoutModes } from '../core/constants/layout';
import { ErrorGuard } from '../core/guards/error.guard';
import { CartGuard } from '../orders/guards/cart.guard';
import { TermsCheckGuard } from './guards/terms-check.guard';
import { StripeRegisteredCheckGuard } from './guards/stripe-registered-check.guard';
import { PetsProfilePageComponent } from '../pets/containers/pets-profiles-page/pets-profile-page.component';
import { PetsShopPageComponent } from '../pets/containers/pets-shop-page/pets-shop-page.component';

const routes: Routes = [
  {
    path: 'home',
    component: PetsShopPageComponent,
    canActivate: [ErrorGuard, AuthGuard, ProfileGuard, StripeRegisteredCheckGuard, TermsCheckGuard],
    data: { layoutMode: LayoutModes.Alternate2 }
  },
  {
    path: 'home/ignoreTermsCheck',
    component: HomePageComponent,
    canActivate: [ErrorGuard, AuthGuard, ProfileGuard, CartGuard],
    data: { layoutMode: LayoutModes.Alternate2 }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
