
import { Component, OnInit, Output, EventEmitter, ViewChild, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PetParentDetail } from '../../models/pet-parent-detail';

import { PhoneTypes, phoneTypes } from '../../../shared/constants/phone-type';
import { PetTypes, species } from '../../../shared/constants/pet';
import { AuthService } from 'src/app/api/api/api';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromCore from "../../../core/reducers";
import { Patterns } from '../../../shared/validation/patterns';

@Component({
  selector: 'app-register-pet-parent',
  templateUrl: './register-pet-parent.component.html',
  styleUrls: ['./register-pet-parent.component.scss']
})
export class RegisterPetParentComponent implements OnInit {

  registerForm: UntypedFormGroup;
  passwordmatch = false;
  vetId: string;
  envCheck: boolean = environment.production;
  phoneTypes: PhoneTypes[] = phoneTypes;
  species: PetTypes[] = species;

  @Input()
  pending: boolean | false;

  @Input()

  isPetParentRegistered: string | null;

  @Input()
  errorMessage: string | null;

  @Input()
  isMobile: boolean | false;

  @Output()
  submitted = new EventEmitter<PetParentDetail>();

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));
  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private authService: AuthService, private modalService: NgbModal, private store: Store<fromCore.State>) {
    this.createForm();
    this.isMobile$.subscribe(value => {
      this.isMobile = value;
    })
    this.registerForm.controls['species'].setValue(this.species[0], { onlySelf: true });
    this.registerForm.controls['phoneType'].setValue(this.phoneTypes[0].name, { onlySelf: true });
  }

  @ViewChild('successModal', { static: true })
  successModal: any;


  createForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.pattern(Patterns.emailPattern)]],
      //password: ['', {validators: [Validators.required, Validators.pattern(Patterns.pwdpattern)],}],
      //confirmpassword: ['',Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern(Patterns.phoneNumberPattern)]],
      phoneType: [],
      petName: ['', Validators.required],
      species: [],
      
    });
  }


  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      var encodedString = params.get('id');
      try {
        this.vetId = atob(encodedString);
      } catch (err) {
        this.vetId = encodedString;
      }
    });
  }
  get f() { return this.registerForm.controls; }

  registerParent() {
    if (this.registerForm.valid) {
      const model = this.registerForm.value;
      const authenticate: PetParentDetail = {
        firstName: model.firstName,
        lastName: model.lastName,
        emailAddress: model.emailAddress,
        phoneNumber: model.phoneNumber,
        //password: model.password,
        phoneType: model.phoneType.toUpperCase(),
        petName: model.petName,
        species: model.species,
        vetEmailId: this.vetId
      };
      this.submitted.emit(authenticate);
    }
  }
 

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }

}
